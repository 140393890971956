<script setup>
import { defineAsyncComponent } from '#imports';
import { useDocumentLinks } from '@/composables/useDocumentLinks';

import UiLinkTag from '@/components/ui/UiLinkTag.vue';
import { useModal } from 'vue-final-modal';
import UiButton from '@/components/ui/button/UiButton.vue';

const { privacyPolicy, termsConditions } = useDocumentLinks();

function openFeedbackPopup() {
  const { open, close } = useModal({
    modalId: 'feedbackIsAuth',
    component: defineAsyncComponent(
      () => import('@/components/popups/FeedbackIsAuth.vue'),
    ),
    attrs: {
      closeModal: () => {
        close();
      },
    },
  });

  open();
}
</script>

<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__body">
        <div class="footer__nav-columns">
          <div class="footer__nav-column">
            <ul class="footer__nav-links-list">
              <li class="footer__nav-links-item">
                <UiLinkTag to="/" class="footer__nav-link">
                  <span class="footer__font footer__font--nav-link">
                    главная
                  </span>
                </UiLinkTag>
              </li>

              <li class="footer__nav-links-item">
                <UiLinkTag to="/products/king-size" class="footer__nav-link">
                  <span class="footer__font footer__font--nav-link">
                    продукция
                  </span>
                </UiLinkTag>
              </li>

              <li class="footer__nav-links-item">
                <UiLinkTag to="/profile" class="footer__nav-link">
                  <span class="footer__font footer__font--nav-link">
                    личный кабинет
                  </span>
                </UiLinkTag>
              </li>
            </ul>
          </div>

          <div class="footer__nav-column">
            <ul class="footer__nav-links-list">
              <li class="footer__nav-links-item">
                <UiLinkTag class="footer__nav-link" @click="openFeedbackPopup">
                  <span class="footer__font footer__font--nav-link">
                    помощь
                  </span>
                </UiLinkTag>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer__copyrights-column">
          <ul class="footer__copyrights-list">
            <li class="footer__copyrights-item">
              <UiLinkTag
                :to="privacyPolicy"
                target="_blank"
                class="footer__copyright-link"
              >
                <span class="footer__font footer__font--copyright-link">
                  ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
                </span>
              </UiLinkTag>
            </li>

            <li class="footer__copyrights-item">
              <UiLinkTag
                :to="termsConditions"
                target="_blank"
                class="footer__copyright-link"
              >
                <span class="footer__font footer__font--copyright-link">
                  ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
                </span>
              </UiLinkTag>
            </li>
          </ul>

          <div class="footer__rights">
            <span class="footer__font footer__font--rights">
              © Winston, 2024
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.footer {
  background: $back-gradient;

  &__container {
    max-width: em(1920);
    margin: 0 auto;
    width: 100%;
    padding: 0 em(120);

    @include media-breakpoint-down(lg) {
      padding: 0 em(87);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 em(32);
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    padding: em(80) 0 em(62);

    @include media-breakpoint-down(lg) {
      padding: em(58) 0 em(44);
    }

    @include media-breakpoint-down(sm) {
      padding: em(48) 0 em(31);
      flex-direction: column;
    }
  }

  &__nav-columns {
    display: flex;
  }

  &__nav-column {
    min-width: em(290);

    @include media-breakpoint-down(lg) {
      min-width: em(135);
    }

    &:not(:last-child) {
      margin-right: em(24);

      @include media-breakpoint-down(lg) {
        margin-right: em(20);
      }

      @include media-breakpoint-down(sm) {
        margin-right: em(27);
      }
    }
  }

  &__nav-links-list {
  }

  &__nav-links-item {
    &:not(:last-child) {
      margin-bottom: em(30);

      @include media-breakpoint-down(lg) {
        margin-bottom: em(20);
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: em(15);
      }
    }
  }

  &__nav-link {
    color: $color-white;
    opacity: 0.8;

    transition: $ease-out $time-normal;
    transition-property: opacity;

    @include hover() {
      opacity: 1;
    }
  }

  &__copyrights-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      margin-top: em(54);
    }
  }

  &__copyrights-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-bottom: em(60);

    @include media-breakpoint-down(lg) {
      margin-bottom: em(40);
    }

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      margin-bottom: em(54);
    }
  }

  &__copyrights-item {
    &:not(:last-child) {
      margin-bottom: em(14);

      @include media-breakpoint-down(lg) {
        margin-bottom: em(10);
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: em(13);
      }
    }
  }

  &__copyright-link {
    position: relative;

    color: $color-white;
    opacity: 0.6;

    transition: $ease-out $time-normal;
    transition-property: opacity;

    &:after {
      content: '';
      position: absolute;
      bottom: em(-3);
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-white;
      opacity: 0.5;

      @include media-breakpoint-down(lg) {
        bottom: em(-1);
      }
    }

    @include hover() {
      opacity: 1;
    }
  }

  &__rights {
    color: $color-white;
    opacity: 0.6;
  }

  &__font {
    &--nav-link {
      @include button;

      @include media-breakpoint-down(lg) {
        font-size: em(12);
        letter-spacing: 0.03em;
      }

      @include media-breakpoint-down(sm) {
        font-size: em(15);
        letter-spacing: 0;
      }
    }

    &--copyright-link {
      display: block;
      @include text;

      @include media-breakpoint-down(lg) {
        font-size: em(9);
        letter-spacing: 0.03em;
        line-height: 1.8;
      }

      @include media-breakpoint-down(sm) {
        font-size: em(12);
        letter-spacing: 0.05em;
        line-height: 1.8;
      }
    }

    &--rights {
      @include text;
      text-transform: none;

      @include media-breakpoint-down(lg) {
        font-size: em(9);
      }

      @include media-breakpoint-down(lg) {
        font-size: em(12);
      }
    }
  }
}
</style>
